
import { Component, Vue } from 'vue-property-decorator'
import { EnginneeringEventInfo } from '@/types/engineering'
import EngineeringHandleResult from './HandleResult.vue'

@Component({
  name: 'Event',
  components: { EngineeringHandleResult }
})
export default class List extends Vue {
  private searchInfo = {
    engineeringName: '',
    eventStatus: '',
    occurStartDate: '',
    occurEndDate: ''
  }

  private loading = false
  private tableData: EnginneeringEventInfo[]= []
  page = 1
  size = 10
  total = 0
  private onShow = false
  private eventsId = ''
  private dateRange: Array<string> = []

  created () {
    this.getData()
  }

  // 获取工程事件列表
  getData () {
    this.loading = true
    if (this.dateRange) {
      this.searchInfo.occurStartDate = this.dateRange[0] || ''
      this.searchInfo.occurEndDate = this.dateRange[1] || ''
    } else {
      this.searchInfo.occurStartDate = ''
      this.searchInfo.occurEndDate = ''
    }
    this.$axios.get<{ total: number; list: Array<EnginneeringEventInfo> }>(this.$apis.engineering.selectEngineeringEventByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增工程事件
  onAdd () {
    this.$router.push({ name: 'AddEvent' })
  }

  // 查看工程事件详情
  onDetail (id: string) {
    this.$router.push({ name: 'DetailEvent', params: { id } })
  }

  // 处理工程事件
  onResult (id: string) {
    this.eventsId = id
    this.onShow = true
  }

  // 删除工程事件
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.engineering.deleteEngineeringEvent, {
          eventsId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.onSearch()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
