
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ResultInfo } from '@/types/engineering'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class HandleResult extends Vue {
  @Prop() readonly onShow!: boolean;
  @Prop() readonly eventsId!: string;
  private info: ResultInfo= {
    resourceFinishedList: [],
    resultMarker: '',
    resultDate: ''
  }

  private rules = {
    resultDate: [
      { required: true, message: '请选择发生日期', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceFinishedList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceFinishedList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.engineering.updateEngineeringEvent, {
          ...this.info,
          eventsId: this.eventsId
        }).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:onShow', false)
    this.info = {
      resourceFinishedList: [],
      resultMarker: '',
      resultDate: ''
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
